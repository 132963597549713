<template>
	<div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'LogoutPage',

	async created() {
		await this.logout();
		window.location = '/login';
	},

	methods: {
		...mapActions({
			logout: 'auth/logout',
		}),
	},
};
</script>